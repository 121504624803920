import { useContext, useEffect, useState } from 'react'
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown, faArrowUp, faPlus } from '@fortawesome/free-solid-svg-icons'

import { toast } from 'react-toastify'
import AppContext from 'context/AppContext'
import listResources from 'Spaces/use-cases/listResources'
import LoadingSpinner from 'components/common/LoadingSpinner'
import getResourceColumns from './ResourcesColumns'
import claimResource from 'Spaces/use-cases/claimResource'
import releaseResource from 'Spaces/use-cases/releaseResource'

const Resources = ({ filters, account, workloads, isReadOnly }) => {
  const { repoFactory, sorElementMapping, selectedYear } = useContext(AppContext)
  const [isLoading, setIsLoading] = useState(false)
  const [resources, setResources] = useState([])
  const [filteredItems, setFilteredItems] = useState([])
  const [sorting, setSorting] = useState([])
  const [search, setSearch] = useState('')
  const [statusFilter, setStatusFilter] = useState({
    open: true,
    claimed: false
  })
  // const [selectedResource, setSelectedResource] = useState(null)

  useEffect(() => {
    setSearch('')
    setStatusFilter({
      open: true,
      claimed: false
    })
    getResources()
  }, [selectedYear, filters, account, repoFactory])

  useEffect(() => {
    // TODO: Add search by workload and tags
    const { open, claimed } = statusFilter
    let filtered = [...resources]
    if (open && !claimed) {
      filtered = filtered.filter((elem) => !elem.claimedStatus?.elementMap)
    }

    if (!open && claimed) {
      filtered = filtered.filter((elem) => elem.claimedStatus?.elementMap)
    }

    if (search) {
      filtered = filtered.filter((elem) => {
        return (
          elem.resourceId.toLowerCase().includes(search.toLowerCase()) ||
          elem.productCode.toLowerCase().includes(search.toLowerCase())
        )
        // elem.tags.toLowerCase().includes(search.toLowerCase())
      })
    }
    setFilteredItems(filtered)
  }, [resources, search, statusFilter])

  useEffect(() => {
    table.toggleAllRowsSelected(false)
  }, [statusFilter])

  const getResources = () => {
    setIsLoading(true)
    listResources(
      {
        searchParams: {
          year: selectedYear,
          accountId: account.accountId,
          elementMap: {
            element1Id: filters.element1,
            element2Id: filters.element2,
            element3Id: filters.element3,
            element4Id: filters.element4
          }
        },
        token: localStorage.getItem('authToken')
      },
      {
        spacesRepo: repoFactory.spacesRepo(),
        observer: {
          success: (response) => {
            setResources(response)
            setIsLoading(false)
          },
          failure: (error) => {
            toast.error('Error fetching resources', {
              hideProgressBar: true
            })
            setIsLoading(false)
          }
        }
      }
    )
  }

  const table = useReactTable({
    data: filteredItems,
    columns: getResourceColumns(filters, workloads, sorElementMapping, isReadOnly),
    state: {
      sorting
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    enableRowSelection: true
  })

  const claim = () => {
    const selectedRows = table.getSelectedRowModel().rows

    if (selectedRows.length === 0) {
      toast.warning('Please select at least a resource to claim', {
        hideProgressBar: true
      })
      return
    }

    if (selectedRows.some((row) => row.original.claimedStatus?.elementMap)) {
      toast.warning('Cannot claim already claimed resources', {
        hideProgressBar: true
      })
      return
    }

    if (!selectedRows.every((row) => row.original.claimedStatus?.reference)) {
      toast.warning('Please select Workload to your Claimed Resource', {
        hideProgressBar: true
      })
      return
    }

    const selectedResources = selectedRows.map((row) => ({
      resourceId: row.original.resourceId,
      accountId: row.original.accountId,
      elementMap: {
        element1Id: filters.element1,
        element2Id: filters.element2,
        element3Id: filters.element3,
        element4Id: filters.element4
      },
      reference: row.original.claimedStatus?.reference
    }))

    setIsLoading(true)
    claimResource(
      {
        token: localStorage.getItem('authToken'),
        accountId: account.accountId,
        resources: selectedResources
      },
      {
        spacesRepo: repoFactory.spacesRepo(),
        observer: {
          success: (response) => {
            toast.success('Resources claimed successfully', {
              hideProgressBar: true
            })
            setIsLoading(false)
            table.toggleAllRowsSelected(false)
            getResources()
          },
          failure: () => {
            toast.error('Error claiming resources', {
              hideProgressBar: true
            })
            setIsLoading(false)
          }
        }
      }
    )
  }

  const release = () => {
    const selectedRows = table.getSelectedRowModel().rows

    if (selectedRows.length === 0) {
      toast.warning('Please select at least a resource to release', {
        hideProgressBar: true
      })
      return
    }

    if (selectedRows.some((row) => !row.original.claimedStatus?.elementMap)) {
      toast.warning('Resource is already open', {
        hideProgressBar: true
      })
      return
    }

    const selectedResources = selectedRows.map((row) => ({
      resourceId: row.original.resourceId,
      accountId: row.original.accountId,
      elementMap: {
        element1Id: filters.element1,
        element2Id: filters.element2,
        element3Id: filters.element3,
        element4Id: filters.element4
      },
      id: row.original.claimedStatus?.id,
      reference: row.original.claimedStatus?.reference
    }))

    releaseResource(
      {
        token: localStorage.getItem('authToken'),
        accountId: account.accountId,
        resources: selectedResources
      },
      {
        spacesRepo: repoFactory.spacesRepo(),
        observer: {
          success: (response) => {
            toast.success('Resources released successfully', {
              hideProgressBar: true
            })
            setIsLoading(false)
            table.toggleAllRowsSelected(false)
            getResources()
          },
          failure: () => {
            toast.error('Error releasing resources', {
              hideProgressBar: true
            })
            setIsLoading(false)
          }
        }
      }
    )
  }

  return (
    <div className="resource-section">
      <div className="mb-3">
        <div className="row">
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm"
              placeholder="Search by Resource, Workload or Tag"
              value={search}
              onChange={(event) => setSearch(event.target.value)}
            />
          </div>
          <div className="col-8">
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="checkbox"
                id="openStatus"
                checked={statusFilter.open}
                onChange={() => setStatusFilter({ ...statusFilter, open: !statusFilter.open })}
              />
              <label className="form-check-label" for="openStatus">
                Open
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                id="claimStatus"
                type="checkbox"
                checked={statusFilter.claimed}
                onChange={() => setStatusFilter({ ...statusFilter, claimed: !statusFilter.claimed })}
              />
              <label className="form-check-label" for="claimStatus" checked={statusFilter.claimed}>
                Claimed
              </label>
            </div>
          </div>
          {/* <div className="col-8">
            <button type="button" className="btn btn-warning btn-sm">
              Check for MAP Eligibility
            </button>
          </div> */}
        </div>
      </div>
      <hr />
      <p className="text-sm text-muted mb-2">
        {search || statusFilter.open || statusFilter.claimed
          ? `Showing ${filteredItems.length} from ${resources.length} results`
          : `Showing ${resources.length} results`}
      </p>
      <div className="table-responsive vertical-scroll mb-3">
        {isLoading && <LoadingSpinner />}
        <table className="table table-bordered table-sticky-header">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    scope="col"
                    className={`${header.id === 'resourceId' ? 'resource-name-cell' : ''}`}
                  >
                    {header.isPlaceholder ? null : (
                      <button
                        {...{
                          className: header.column.getCanSort()
                            ? 'w-100 d-flex justify-content-between align-items-center user-select-none'
                            : '',
                          onClick: header.column.getToggleSortingHandler()
                        }}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {{
                          asc: <FontAwesomeIcon className="ml-2" icon={faArrowUp} />,
                          desc: <FontAwesomeIcon className="ml-2" icon={faArrowDown} />
                        }[header.column.getIsSorted()] ?? null}
                      </button>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {filteredItems.length === 0 && (
              <tr>
                <td colSpan="7" className="text-center">
                  No results found
                </td>
              </tr>
            )}
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {!isReadOnly && (
        <div className="d-flex justify-content-center gap-4">
          <button type="button" className="btn btn-primary btn-sm" onClick={claim}>
            Claim
          </button>
          <button type="button" className="btn btn-success btn-sm" onClick={release}>
            Release
          </button>
        </div>
      )}
      {/* <Modal show={selectedResource} onHide={() => setSelectedResource(null)} size="lg">
        <Modal.Body>
          <h5>New Program</h5>
          <Card className="section-gray mb-3">
            <Card.Body>
              <ProgramForm />
            </Card.Body>
          </Card>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setSelectedResource(null)}>
            Close
          </Button>
          <Button variant="primary" onClick={() => {}}>
            Save
          </Button>
        </Modal.Footer>
      </Modal> */}
    </div>
  )
}
export default Resources
