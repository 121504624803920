import React, { Fragment, useEffect, useState } from 'react'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown, faArrowUp, faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { Col, Row } from 'react-bootstrap'
import getPaginationControls from 'components/common/Utils/getPaginationControls'
import dollarPriceFormatter from 'formatters/dollar_price_formatter'
import { FormattedMessage } from 'react-intl'
import moment from 'moment'

const columnHelper = createColumnHelper()

function CreditList({ creditMappings, setSelectedCredit }) {
  const [data, setData] = useState([])
  const [filteredItems, setFilteredItems] = useState(creditMappings)
  const [searchText, setSearchText] = useState('')
  const [sorting, setSorting] = useState([])

  useEffect(() => {
    if (creditMappings) {
      setData(creditMappings)
    }
  }, [creditMappings])

  useEffect(() => {
    if (searchText) {
      const filteredItems = data.filter((item) => item.promotionCode.toLowerCase().includes(searchText.toLowerCase()))
      setFilteredItems(filteredItems)
    } else {
      setFilteredItems(data)
    }
  }, [searchText, data])

  const columns = [
    columnHelper.accessor('promotionCode', {
      header: () => 'Promotion Code'
    }),
    columnHelper.accessor('creditMapping.amount', {
      header: () => 'Amount',
      cell: (info) => `${dollarPriceFormatter(info.getValue())}`
    }),
    columnHelper.accessor('balance', {
      header: () => 'Balance',
      cell: (info) => `${dollarPriceFormatter(info.getValue())}`
    }),
    columnHelper.display({
      id: 'action',
      header: () => 'Action',
      cell: ({ row }) => {
        return (
          <div className="text-center">
            <button className="btn btn-sm btn-remap" onClick={() => setSelectedCredit(row.original)}>
              Re-Apply
            </button>

            <button
              {...{
                onClick: row.getToggleExpandedHandler()
              }}
              className="btn btn-sm btn-detail "
            >
              Details{' '}
              {row.getIsExpanded() ? (
                <FontAwesomeIcon icon={faChevronDown} />
              ) : (
                <FontAwesomeIcon icon={faChevronRight} />
              )}
            </button>
          </div>
        )
      }
    })
  ]

  const table = useReactTable({
    data: filteredItems,
    columns,
    state: {
      sorting
    },
    onSortingChange: setSorting,
    getRowCanExpand: () => true,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getExpandedRowModel: getExpandedRowModel()
  })

  const onSearchKey = (event) => {
    const { value } = event.target
    setSearchText(value)
  }

  return (
    <div className="simple-card">
      <h1>Credit List</h1>
      <Row className="mb-2">
        <Col md={4}>
          <input
            type="text"
            value={searchText}
            className="form-control"
            placeholder="Search by Promotion Code or Financial Scope"
            onChange={onSearchKey}
          />
        </Col>
        <Col md={8}>{getPaginationControls(table)}</Col>
      </Row>
      <div className="table-responsive">
        <table className="table table-bordered">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <button
                        {...{
                          className: header.column.getCanSort()
                            ? 'd-flex justify-content-between align-items-center user-select-none'
                            : '',
                          onClick: header.column.getToggleSortingHandler()
                        }}
                        className="sorting-button"
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {{
                          asc: <FontAwesomeIcon className="ml-2" icon={faArrowUp} />,
                          desc: <FontAwesomeIcon className="ml-2" icon={faArrowDown} />
                        }[header.column.getIsSorted()] ?? null}
                      </button>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody>
            {table.getRowModel().rows.map((row) => (
              <Fragment key={row.id}>
                <tr>
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                  ))}
                </tr>
                {row.getIsExpanded() && (
                  <tr className="expanded-row">
                    <td colSpan={row.getVisibleCells().length}>
                      <DetailsTable data={row.original} />
                    </td>
                  </tr>
                )}
              </Fragment>
            ))}
          </tbody>
        </table>
      </div>
      {getPaginationControls(table)}
    </div>
  )
}

function DetailsTable({ data }) {
  const { creditMapping } = data
  const { allocations } = creditMapping
  const { breakdown } = allocations[0]

  const items = breakdown.map((item) => ({
    ...item,
    reference: creditMapping.reference,
    effectiveDates: `${moment(creditMapping.startDate).format('MM-DD-YYYY')} - ${moment(creditMapping.endDate).format(
      'MM-DD-YYYY'
    )}`
  }))

  const columns = [
    columnHelper.accessor('element1Id', {
      header: () => <FormattedMessage id="sor.element1Name" defaultMessage="Business Unit" />
    }),
    columnHelper.accessor('element2Id', {
      header: () => <FormattedMessage id="sor.element2Name" defaultMessage="Department" />
    }),
    columnHelper.accessor('element3Id', {
      header: () => <FormattedMessage id="sor.element3Name" defaultMessage="Portfolio" />
    }),
    columnHelper.accessor('element4Id', {
      header: () => <FormattedMessage id="sor.element4Name" defaultMessage="Product" />
    }),
    columnHelper.accessor('amount', {
      header: () => 'Amount',
      cell: (info) => `${dollarPriceFormatter(info.getValue())}`
    }),
    columnHelper.accessor('reference', {
      header: () => 'Reference'
    }),
    columnHelper.accessor('effectiveDates', {
      header: () => 'Effective Dates'
    })
  ]

  const table = useReactTable({
    data: items,
    columns,
    getCoreRowModel: getCoreRowModel()
  })

  return (
    <div className="table-responsive">
      <table className={`table`}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
export default CreditList
