import { createColumnHelper } from '@tanstack/react-table'
import Select from 'react-select'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

const columnHelper = createColumnHelper()

const getResourceColumns = (filters = [], workloads = [], sorElementMapping, isReadOnly) => {
  const workloadOptions = workloads.map((workload) => ({ value: workload, label: workload }))
  return [
    ...(!isReadOnly
      ? [
          columnHelper.accessor((row) => row, {
            id: 'select',
            header: ({ table }) => {
              return (
                <input
                  type="checkbox"
                  checked={table.getIsAllRowsSelected()}
                  onChange={table.getToggleAllRowsSelectedHandler()}
                />
              )
            },
            cell: ({ row }) => {
              const { elementMap } = row.original.claimedStatus || {}

              if (elementMap) {
                const isOwner = (elementMap.element1Id =
                  filters.element1 &&
                  elementMap.element2Id === filters.element2 &&
                  elementMap.element3Id === filters.element3 &&
                  elementMap.element4Id === filters.element4)

                if (!isOwner) {
                  return <></>
                }
              }

              return <input type="checkbox" checked={row.getIsSelected()} onChange={row.getToggleSelectedHandler()} />
            },
            enableSorting: false
          })
        ]
      : []),
    columnHelper.accessor((row) => row, {
      id: 'status',
      header: () => 'Status',
      cell: ({ row }) => {
        const { claimedBy, elementMap } = row.original.claimedStatus || {}
        if (elementMap) {
          const isOwner = (elementMap.element1Id =
            filters.element1 &&
            elementMap.element2Id === filters.element2 &&
            elementMap.element3Id === filters.element3 &&
            elementMap.element4Id === filters.element4)

          if (isOwner) {
            return <span className="badge badge-claimed-owner">Claimed</span>
          }
          return (
            <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 400 }}
              overlay={(props) => (
                <Tooltip id="button-tooltip" {...props}>
                  <div className="resource-popup">
                    <div className="text-start mb-1">
                      <h6>{sorElementMapping.element4}</h6>
                      {elementMap.element4Id}
                    </div>
                    <div className="text-start">
                      <h6>Claimed by</h6>
                      {claimedBy}
                    </div>
                  </div>
                </Tooltip>
              )}
            >
              <span className="badge badge-claimed">Claimed</span>
            </OverlayTrigger>
          )
        }
        return <span className="badge badge-open">Open</span>
      },
      enableSorting: false
    }),
    // columnHelper.display({
    //   id: 'modify',
    //   header: () => 'Modify',
    //   cell: ({ row }) => {
    //     return (
    //       <div className="action-buttons text-center">
    //         <button
    //           {...{
    //             onClick: () => setSelectedResource(row.original)
    //           }}
    //           className="btn btn-sm btn-detail "
    //         >
    //           <FontAwesomeIcon icon={faPlus} />
    //         </button>
    //       </div>
    //     )
    //   }
    // }),
    columnHelper.accessor('productCode', {
      header: () => 'Service Name'
    }),
    columnHelper.accessor('resourceId', {
      header: () => 'Resource Name',
      cell: (info) => {
        const resourceName = info.getValue()
        return (
          <div style={{ maxWidth: '250px' }}>
            <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 400 }}
              overlay={(props) => (
                <Tooltip id="button-tooltip" {...props}>
                  {resourceName}
                </Tooltip>
              )}
            >
              <div className="resource-cell">{resourceName}</div>
            </OverlayTrigger>
          </div>
        )
      }
    }),
    columnHelper.accessor('workload', {
      header: () => 'Workload',
      cell: ({ row }) => {
        const { claimedStatus } = row.original
        if (claimedStatus?.elementMap || isReadOnly) {
          return <span>{claimedStatus?.reference}</span>
        }
        return (
          <Select
            classNamePrefix="select"
            options={workloadOptions}
            defaultValue={workloadOptions.find((option) => option.value === row.original.claimedStatus?.reference)}
            onChange={(option) => {
              row.original.claimedStatus = {
                ...row.original.claimedStatus,
                reference: option.value
              }
            }}
          />
        )
      }
    }),
    columnHelper.accessor('tags', {
      header: () => 'Tags',
      cell: (info) => {
        const tags = 'tag1, tag2, tag3, tag4, tag5, tag6, tag7, tag8, tag9, tag10'
        return (
          <OverlayTrigger
            placement="left"
            delay={{ show: 250, hide: 400 }}
            overlay={(props) => (
              <Tooltip id="button-tooltip" {...props}>
                {tags}
              </Tooltip>
            )}
          >
            <div className="link-primary text-center">View</div>
          </OverlayTrigger>
        )
      }
    })
  ]
}

export default getResourceColumns
