import React, { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import CreditList from './CreditList'
import CreditForm from './CreditForm'

import './CreditManagement.scss'
import AppContext from 'context/AppContext'
import LoadingSpinner from 'components/common/LoadingSpinner'
import getCreditMapping from '../use-cases/getCreditMapping'
import createCreditMapping from '../use-cases/createCreditMapping'
import { Modal } from 'react-bootstrap'

const CreditManagement = () => {
  const { repoFactory, sorElements, selectedYear } = useContext(AppContext)
  const [isLoading, setIsLoading] = useState(false)
  const [creditMappings, setCreditMappings] = useState([])
  const [selectedCredit, setSelectedCredit] = useState(null)
  const [show, setShow] = useState(false)

  useEffect(() => {
    getCredit()
  }, [selectedYear, repoFactory])

  const getCredit = async () => {
    setIsLoading(true)
    getCreditMapping(
      {
        year: selectedYear,
        token: localStorage.getItem('authToken')
      },
      {
        creditRepo: repoFactory.creditRepo(),
        observer: {
          success: (data) => {
            setIsLoading(false)
            setCreditMappings(data)
          },
          failure: (error) => {
            setIsLoading(false)
            toast.error('There was an error, please try again.')
          }
        }
      }
    )
  }

  const createCredit = async (credit) => {
    setIsLoading(true)
    createCreditMapping(
      {
        token: localStorage.getItem('authToken'),
        creditMapping: credit,
        year: selectedYear
      },
      {
        creditRepo: repoFactory.creditRepo(),
        observer: {
          success: (data) => {
            setIsLoading(false)
            getCredit()
            toast.success('Vendor details saved successfully.')
          },
          failure: (error) => {
            toast.error('There was an error, please try again.')
            setIsLoading(false)
          }
        }
      }
    )
  }

  return (
    <div className="credit-management">
      {isLoading && <LoadingSpinner />}
      {sorElements && <CreditForm sorElements={sorElements} save={createCredit} />}
      <CreditList creditMappings={creditMappings} setSelectedCredit={setSelectedCredit} />
      <Modal className="credit-modal" show={selectedCredit} onHide={() => setSelectedCredit(false)} size="xl">
        <Modal.Body>
          <h5>Re-Apply Credit</h5>
          <CreditForm data={selectedCredit} sorElements={sorElements} save={createCredit} />
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-secondary btn-sm" onClick={() => setSelectedCredit(false)}>
            Close
          </button>
          <button type="button" className="btn btn-primary btn-sm" onClick={() => {}}>
            Save
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
export default CreditManagement
