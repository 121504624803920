function SpacesRepo({ GRAPHQL_URL, GRAPHQL_API_KEY, httpClient }) {
  this.listSpaces = ({ year, token }, { success, failure }) => {
    const query = `query ($token: String!, $year: Int!) {
                    listSpaces(
                      spaceSearch: {
                        token: $token, 
                        year: $year
                      }) 
                      {
                        data {
                          element1
                          element2
                          element3
                          element4
                          year
                          updatedTimeStamp
                          estimates {
                            accountId
                            environment
                            accountDescription
                            accountName
                            status
                            allocation {
                              percentage
                              startDate
                              insertedDate
                              endDate
                              environment
                            }
                            breakdown {
                              total
                              startDate
                              endDate
                              estimateLink
                              reference
                            }
                          }
                        }
                        error {
                          code
                          message
                          statusCode
                        }
                      }
                    }
`

    httpClient.request(
      {
        method: 'POST',
        url: GRAPHQL_URL,
        headers: {
          'x-api-key': GRAPHQL_API_KEY
        },
        data: {
          query,
          variables: {
            token,
            year
          }
        }
      },
      {
        success: (response) => {
          const { listSpaces } = response.data.data
          success(listSpaces)
        },
        failure: (error) => failure(error)
      }
    )
  }

  this.createOrUpdateSpace = (
    { year, token, element1, element2, element3, element4, estimates },
    { success, failure }
  ) => {
    const query = `mutation ($token: String!, $year: Int!, $estimates: [EstimatesInput]!, $element1Id: String!, $element2Id: String!, $element3Id: String!, $element4Id: String!) {
              createOrUpdateSpaceDetails(space: {estimates: $estimates, element1Id: $element1Id, element2Id: $element2Id, element3Id: $element3Id, element4Id: $element4Id, token: $token, year: $year}) {
                data
                error {
                  code
                  message
                  statusCode
                }
              }
          }`

    httpClient.request(
      {
        method: 'POST',
        url: GRAPHQL_URL,
        headers: {
          'x-api-key': GRAPHQL_API_KEY
        },
        data: {
          query,
          variables: {
            token,
            year,
            element1Id: element1,
            element2Id: element2,
            element3Id: element3,
            element4Id: element4,
            estimates
          }
        }
      },
      {
        success: (response) => {
          if (response.data.errors) {
            failure(response.data.errors)
            return
          }
          const { createOrUpdateSpaceDetails } = response.data.data
          success(createOrUpdateSpaceDetails)
        },
        failure: (error) => failure(error)
      }
    )
  }

  this.listResources = ({ searchParams, token }, { success, failure }) => {
    const query = `
      query($searchParams: ListResourceInput!, $token: String!) {
        listResources(
          searchParams: $searchParams,
          token: $token
        )
        {
          data {
            accountId
            claimedStatus {
              id
              claimedBy
              elementMap {
                element1Id
                element2Id
                element3Id
                element4Id
              }
              reference
            }
            creationDate
            region
            resourceId
            productCode
            tags {
              key
              value
            }
          }
          error {
            code
            message
            statusCode
          }
        }
      }
    `
    httpClient.request(
      {
        method: 'POST',
        url: GRAPHQL_URL,
        headers: {
          'x-api-key': GRAPHQL_API_KEY
        },
        data: {
          query,
          variables: {
            searchParams,
            token
          }
        }
      },
      {
        success: (response) => {
          const { data, errors } = response.data
          if (errors) {
            failure(errors)
            return
          }
          const { listResources } = data
          success(listResources)
        },
        failure: (error) => failure(error)
      }
    )
  }

  this.claimResource = ({ token, accountId, resources }, { success, failure }) => {
    const query = `
      mutation claimResourceMutation(
        $token: String!, 
        $accountId: String!, 
        $resources: [ClaimResourceBreakdown]! 
      ) {
        claimResource(
          token: $token, 
          accountId: $accountId, 
          resources: $resources
        ) {
          data {
            resourceId
            status
          }
          error {
            code
            message
            statusCode
          }
        }
      }
    `
    httpClient.request(
      {
        method: 'POST',
        url: GRAPHQL_URL,
        headers: {
          'x-api-key': GRAPHQL_API_KEY
        },
        data: {
          query,
          variables: {
            token,
            accountId,
            resources
          }
        }
      },
      {
        success: (response) => {
          const { data, errors } = response.data
          if (errors) {
            failure(errors)
            return
          }
          const { claimResource } = data
          success(claimResource)
        },
        failure: (error) => {
          failure(error)
        }
      }
    )
  }

  this.releaseResource = ({ token, accountId, resources }, { success, failure }) => {
    const query = `
      mutation releaseResourceMutation(
        $token: String!, 
        $accountId: String!, 
        $resources: [ReleaseResourceBreakdown]! 
      ) {
        releaseResource(
          token: $token, 
          accountId: $accountId, 
          resources: $resources
        ) {
          data {
            id
            resourceId
            status
          }
          error {
            code
            message
            statusCode
          }
        }
      }
    `

    httpClient.request(
      {
        method: 'POST',
        url: GRAPHQL_URL,
        headers: {
          'x-api-key': GRAPHQL_API_KEY
        },
        data: {
          query,
          variables: {
            token,
            accountId,
            resources
          }
        }
      },
      {
        success: (response) => {
          const { releaseResource } = response.data.data
          const { data, error } = releaseResource

          if (error) {
            failure(error)
            return
          }

          success(data)
        },
        failure: (error) => {
          failure(error)
        }
      }
    )
  }
}

export default SpacesRepo
