import Accordion from 'react-bootstrap/Accordion'

const CreditInfo = () => {
  return (
    <div>
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Credit info</Accordion.Header>
          <Accordion.Body>
            <div className="table-responsive">
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <td>
                      <strong>Credit ($2,345.33)</strong> Balance as of end of last month
                    </td>
                    <td>$5000 01-01-2024 06-30-2024 (01-04-2024 11:26:19 AM)</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  )
}
export default CreditInfo
