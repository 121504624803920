import { forwardRef, useEffect, useState } from 'react'
import { useFormik } from 'formik'
import DatePicker from 'react-datepicker'
import CurrencyInput from 'react-currency-input-field'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FormattedMessage } from 'react-intl'
import ElementList from './ElementList'

import 'react-datepicker/dist/react-datepicker.css'
import LoadingSpinner from 'components/common/LoadingSpinner'
import ERROR_MESSAGES from 'utils/errorMessages'

const validate = (values) => {
  const errors = {}

  if (!values.promotionCode) {
    errors.promotionCode = ERROR_MESSAGES.REQUIRED
  }

  if (!values.amount) {
    errors.amount = ERROR_MESSAGES.REQUIRED
  } else if (values.amount <= 0) {
    errors.amount = 'Credit amount must be greater than 0'
  } else if (values.amount > 400000) {
    errors.amount = 'Credit amount must be less than $400,000'
  }

  if (!values.startDate) {
    errors.startDate = ERROR_MESSAGES.REQUIRED
  }

  if (!values.endDate) {
    errors.endDate = ERROR_MESSAGES.REQUIRED
  }

  return errors
}

const CreditForm = ({ data = null, sorElements, save }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [areSorElementsLoaded, setAreSorElementsLoaded] = useState(false)

  const DateButton = forwardRef(({ value, onClick }, ref) => (
    <button type="button" className="btn btn-primary" onClick={onClick} ref={ref}>
      {value || 'Select Date'}
    </button>
  ))

  useEffect(() => {
    setIsLoading(true)
    const { elementList1 } = sorElements

    if (elementList1) {
      setIsLoading(false)
      setAreSorElementsLoaded(true)
    }
  }, [sorElements])

  useEffect(() => {
    console.log('data', data)
  }, [data])

  const formik = useFormik({
    initialValues: {
      reference: '',
      promotionCode: '',
      amount: '',
      startDate: '',
      endDate: '',
      allocations: {
        startDate: '',
        endDate: '',
        breakdown: [
          {
            element1Id: '',
            element2Id: '',
            element3Id: '',
            element4Id: '',
            amount: ''
          }
        ]
      }
    },
    validate,
    validateOnMount: true,
    onSubmit: () => {
      const breakdown = [...formik.values.allocations.breakdown].map((item) => ({
        ...item,
        amount: +item.amount
      }))

      const creditMapping = {
        ...formik.values,
        amount: +formik.values.amount,
        allocations: {
          ...formik.values.allocations,
          endDate: formik.values.endDate,
          breakdown
        }
      }
      save(creditMapping)
    }
  })

  const addRow = () => {
    const { breakdown } = formik.values.allocations
    const copy = [...breakdown]
    copy.push({
      element1Id: '',
      element2Id: '',
      element3Id: '',
      element4Id: '',
      amount: ''
    })
    formik.setFieldValue('allocations.breakdown', copy)
  }

  const removeRow = (index) => {
    const { breakdown } = formik.values.allocations
    const copy = [...breakdown]
    copy.splice(index, 1)
    formik.setFieldValue('allocations.breakdown', [...copy])
  }

  const { elementList1, elementList2, elementList3, elementList4 } = sorElements

  return (
    <>
      {isLoading && <LoadingSpinner />}
      <div className="card mb-3">
        <div className="card-body">
          <form onSubmit={formik.handleSubmit} autoComplete="off" className="mb-4">
            <div className="credit-form mb-3">
              <div className="field">
                <label htmlFor="promotionCode">Promotion Code</label>
                <input
                  id="promotionCode"
                  type="text"
                  className="form-control"
                  value={formik.values.promotionCode}
                  onChange={formik.handleChange}
                />
                {formik.errors.promotionCode && formik.touched.promotionCode && (
                  <div className="text-error">{formik.errors.promotionCode}</div>
                )}
              </div>
              <div className="field">
                <label htmlFor="creditAmount">Credit Amount</label>
                <CurrencyInput
                  prefix="$"
                  id="creditAmount"
                  value={formik.values.amount}
                  decimalsLimit={2}
                  onValueChange={(value) => {
                    formik.setFieldValue('amount', value)
                  }}
                  className="form-control"
                  placeholder="$0.00"
                />
                {formik.errors.amount && formik.touched.amount && (
                  <div className="text-error">{formik.errors.amount}</div>
                )}
              </div>
              <div className="field">
                <label htmlFor="reference">Reference</label>
                <input
                  id="reference"
                  type="text"
                  className="form-control"
                  value={formik.values.reference}
                  onChange={formik.handleChange}
                />
                {formik.errors.reference && formik.touched.reference && (
                  <div className="text-error">{formik.errors.reference}</div>
                )}
              </div>
              <div className="field">
                <label htmlFor="reference">Date Applied to AWS</label>
                <div className="d-block">
                  <DatePicker
                    showIcon
                    autoComplete="off"
                    id="dateAppliedToAWS"
                    selected={formik.values.startDate}
                    minDate={new Date()}
                    onChange={(date) => {
                      formik.setFieldValue('startDate', date)
                    }}
                    className="form-control"
                  />
                </div>
                {formik.errors.startDate && formik.touched.startDate && (
                  <div className="text-error">{formik.errors.startDate}</div>
                )}
              </div>

              <div className="field">
                <label htmlFor="expirationDate">Expiration</label>
                <div className="d-block">
                  <DatePicker
                    showIcon
                    autoComplete="off"
                    id="expirationDate"
                    minDate={formik.values.startDate}
                    selected={formik.values.endDate}
                    onChange={(date) => {
                      formik.setFieldValue('endDate', date)
                    }}
                    disabled={formik.values.startDate === ''}
                    className="form-control"
                  />
                </div>
                {formik.errors.endDate && formik.touched.endDate && (
                  <div className="text-error">{formik.errors.endDate}</div>
                )}
              </div>
            </div>

            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>
                    <FormattedMessage id="sor.element1Name" defaultMessage="Business Unit" />
                  </th>
                  <th>
                    <FormattedMessage id="sor.element2Name" defaultMessage="Department" />
                  </th>
                  <th>
                    <FormattedMessage id="sor.element3Name" defaultMessage="Portfolio" />
                  </th>
                  <th>
                    <FormattedMessage id="sor.element4Name" defaultMessage="Product" />
                  </th>
                  <th>Allocation</th>
                  <th>Actions</th>
                </tr>
              </thead>
              {areSorElementsLoaded && (
                <tbody>
                  {formik.values.allocations.breakdown.map((item, index) => (
                    <tr key={`allocation-${index}`}>
                      <td className="text-center">
                        {index === 0 && (
                          <DatePicker
                            selected={formik.values.allocations.startDate}
                            onChange={(date) => formik.setFieldValue('allocations.startDate', date)}
                            customInput={<DateButton />}
                          />
                        )}
                      </td>
                      <td>
                        <ElementList
                          elementType="element1"
                          elements={elementList1}
                          onChange={(data) =>
                            formik.setFieldValue(`allocations.breakdown[${index}].element1Id`, data.id)
                          }
                          dropDownLabel={item.element1Id || 'please select'}
                        />
                      </td>
                      <td>
                        <ElementList
                          elementType="element2"
                          elements={elementList2.filter((el) => el.element1Id === item.element1Id)}
                          onChange={(data) =>
                            formik.setFieldValue(`allocations.breakdown[${index}].element2Id`, data.id)
                          }
                          dropDownLabel={item.element2Id || 'please select'}
                        />
                      </td>
                      <td>
                        <ElementList
                          elementType="element3"
                          elements={elementList3.filter((el) => el.element2Id === item.element2Id)}
                          onChange={(data) =>
                            formik.setFieldValue(`allocations.breakdown[${index}].element3Id`, data.id)
                          }
                          dropDownLabel={item.element3Id || 'please select'}
                        />
                      </td>
                      <td>
                        <ElementList
                          elementType="element4"
                          elements={elementList4.filter((el) => el.element3Id === item.element3Id)}
                          onChange={(data) =>
                            formik.setFieldValue(`allocations.breakdown[${index}].element4Id`, data.id)
                          }
                          dropDownLabel={item.element4Id || 'please select'}
                        />
                      </td>
                      <td>
                        <CurrencyInput
                          prefix="$"
                          decimalsLimit={2}
                          value={item.amount}
                          onValueChange={(value) =>
                            formik.setFieldValue(`allocations.breakdown[${index}].amount`, value)
                          }
                          className="form-control"
                          placeholder="$0.00"
                        />
                      </td>
                      <td className="pt-3 text-center">
                        {formik.values.allocations.breakdown.length - 1 === index ? (
                          <button type="button" onClick={addRow} className="me-3">
                            <FontAwesomeIcon icon={faPlusCircle} className="add-more-icon" />
                          </button>
                        ) : null}

                        {formik.values.allocations.breakdown.length > 1 ? (
                          <button type="button" onClick={() => removeRow(index)}>
                            <FontAwesomeIcon icon={faTimesCircle} className="remove-icon" />
                          </button>
                        ) : null}
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
            <div className="text-center">
              <button className="btn btn-primary btn-sm">Apply</button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}
export default CreditForm
