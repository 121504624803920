import React, { Fragment, useContext, useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import CurrencyInput from 'react-currency-input-field'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'

import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import AppContext from 'context/AppContext'

const estimateObj = {
  estimateLink: '',
  reference: '',
  startDate: '',
  endDate: '',
  total: ''
}

function getDaysInMonth(year, month) {
  return new Date(year, month, 0).getDate()
}

const Estimates = ({ account, save, isReadOnly }) => {
  const [estimates, setEstimates] = useState(account.breakdown || [])
  const { selectedYear } = useContext(AppContext)

  useEffect(() => {
    if (account.breakdown?.length) {
      setEstimates(account.breakdown)
    } else if (!isReadOnly) {
      setEstimates([{ ...estimateObj }])
    }
  }, [account.breakdown, isReadOnly])

  const add = () => {
    setEstimates([...estimates, { ...estimateObj }])
  }

  const remove = (index) => {
    const newItems = [...estimates]
    newItems.splice(index, 1)
    if (newItems.length === 0) {
      newItems.push({ ...estimateObj })
    }
    setEstimates(newItems)
  }

  const onInputChange = (name, value, index) => {
    const newItems = [...estimates]
    newItems[index][name] = value
    setEstimates(newItems)
  }

  const prepareAndSave = () => {
    let breakdown = estimates.filter((estimate) => Object.values(estimate).some((val) => val !== ''))
    breakdown = breakdown.map((el) => ({
      ...el,
      total: parseFloat(el.total)
    }))

    const updatedAccount = { ...account, breakdown }
    save(updatedAccount)
  }

  return (
    <>
      <table className="estimate-table table table-bordered">
        <thead>
          <tr>
            <th>Workload</th>
            <th>Estimate link</th>
            <th>Monthly Cost</th>
            <th>Start date</th>
            <th>End date</th>
            {!isReadOnly && <th>Options</th>}
          </tr>
        </thead>
        <tbody>
          {estimates.length === 0 && (
            <tr>
              <td colSpan={6} className="text-muted text-center">
                No Estimates
              </td>
            </tr>
          )}
          {estimates.map((estimate, index) => (
            <Fragment key={`estimate-${index}`}>
              {isReadOnly ? (
                <tr>
                  <td>{estimate.reference}</td>
                  <td>
                    <a href={estimate.estimateLink} target="_blank" rel="noreferrer">
                      {estimate.estimateLink}
                    </a>
                  </td>
                  <td className="text-center">
                    {estimate.total
                      ? estimate.total.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
                      : ''}
                  </td>
                  <td className="text-center">
                    {estimate.startDate ? moment(estimate.startDate).format('MM/DD/YY') : ''}
                  </td>
                  <td className="text-center">{estimate.endDate ? moment(estimate.endDate).format('MM/DD/YY') : ''}</td>
                </tr>
              ) : (
                <tr>
                  <td>
                    <input
                      name="reference"
                      className="form-control"
                      value={estimate.reference || ''}
                      onChange={({ target }) => onInputChange(target.name, target.value, index)}
                    />
                  </td>
                  <td>
                    <input
                      name="estimateLink"
                      className="form-control"
                      value={estimate.estimateLink || ''}
                      onChange={({ target }) => onInputChange(target.name, target.value, index)}
                    />
                  </td>
                  <td>
                    <CurrencyInput
                      prefix="$"
                      name="total"
                      placeholder="Monthly Cost from Estimate"
                      value={estimate.total}
                      decimalsLimit={2}
                      onValueChange={(value) => onInputChange('total', value, index)}
                      className="form-control text-center"
                    />
                  </td>
                  <td>
                    <DatePicker
                      autoComplete="off"
                      name="startDate"
                      selected={estimate.startDate ? new Date(estimate.startDate) : ''}
                      onChange={(date) => onInputChange('startDate', date, index)}
                      filterDate={(date) => {
                        const day = date.getDate()
                        const year = date.getFullYear()
                        return day === 1 && year === selectedYear
                      }}
                      placeholderText="Click to select a start date"
                      className="form-control"
                    />
                  </td>
                  <td>
                    <DatePicker
                      autoComplete="off"
                      name="endDate"
                      selected={estimate.endDate ? new Date(estimate.endDate) : ''}
                      onChange={(date) => onInputChange('endDate', date, index)}
                      filterDate={(date) => {
                        const day = date.getDate()
                        const d = new Date(date)
                        const currentYear = d.getFullYear()
                        const currentMonth = d.getMonth() + 1
                        return day === getDaysInMonth(currentYear, currentMonth)
                      }}
                      placeholderText="Click to select a end date"
                      className="form-control"
                    />
                  </td>
                  <td className="estimate-options">
                    {index === estimates.length - 1 && (
                      <button type="button" onClick={add}>
                        <FontAwesomeIcon icon={faPlusCircle} className="plus-icon" color="green" />
                      </button>
                    )}
                    <button type="button" onClick={() => remove(index)}>
                      <FontAwesomeIcon icon={faTimesCircle} className="remove-icon" color="red" />
                    </button>
                  </td>
                </tr>
              )}
            </Fragment>
          ))}
        </tbody>
      </table>
      {!isReadOnly && (
        <div className="text-center">
          <button className="btn btn-primary btn-sm" onClick={prepareAndSave}>
            Save
          </button>
        </div>
      )}
    </>
  )
}

export default Estimates
