const getQuickSightTopicURL = ({ accountIdList, topicIdList }, { observer, biRepo }) => {
  biRepo.getQuickSightTopicUrl(
    { accountIdList, topicIdList },
    {
      success: (response) => {
        if (response.getQuickSightTopicUrl.error) {
          observer.errorGettingUrl()
          return
        }
        observer.receiveQuickSightUrl(response.getQuickSightTopicUrl.data)
      },
      failure: () => {
        observer.errorGettingUrl()
      }
    }
  )
}

export default getQuickSightTopicURL
